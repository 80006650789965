import React from 'react';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  InputBase,
  IconButton,
  Typography,
} from '@material-ui/core';

// import Typography from 'src/material/Typography';

const useStylesInputButton = makeStyles((theme) => ({
  root: {
    width: 100 + 'vw !important',
    height: 100 + 'vh !important',
    border: '1.5px solid #80FFDB',
    background: '#282c34'
    // borderRadius: 38 / theme.screenSize + 'vw',
    // display: 'flex',
    // alignItems: 'center',
    // boxShadow:
    //   '0 0 0rem #fff, 0 0 0rem #fff, 0 0 0.5rem #80FFDB, 0 0 0rem #80FFDB, 0 0 0rem #80FFDB, inset 0 0 0rem #80FFDB'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    width: 108 / theme.screenSize + 'vw',
    height: 38 / theme.screenSize + 'vw',
    borderRadius:
      ' 0 ' + 38 / theme.screenSize + 'vw ' + 38 / theme.screenSize + 'vw 0',
    background: '#80FFDB !important',
    color: '#242526'
    //   padding: 10,
  },
  divider: {
    height: 28,
    margin: 4
  },
  boxFilter: {
    display: 'flex',
    alignItems: 'center',
    width: 322 / theme.screenSize + 'vw',
    height: 38 / theme.screenSize + 'vw'
    // filter: 'drop-shadow(0px 0px 13px #80FFDB)'
  }
}));


const useStyles = makeStyles((theme) => ({
  root: {
    width: 90 + 'vw !important',
    height: 90 + 'vh !important',
    border: '1.5px solid #80FFDB',
    background: '#282c34',
    boxShadow:
      '0 0 0rem #fff, 0 0 0rem #fff, 0 0 0.5rem #80FFDB, 0 0 0rem #80FFDB, 0 0 0rem #80FFDB, inset 0 0 0rem #80FFDB'
},
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  gradientTitle: {
    width: '100%',
    fontSize: 30,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 800,
    // lineHeight: 28,
    background: '-webkit-linear-gradient(#9BD2C8, #4EC5AF)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  },
  title: {
    width: '100%',
    fontSize: 25,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 800,
    // lineHeight: 28,
    // background: '-webkit-linear-gradient(#9BD2C8, #4EC5AF)',
    // '-webkit-background-clip': 'text',
    // '-webkit-text-fill-color': 'transparent'
  },
  description: {
    width: 324 / theme.screenSize + 'vw'
  },
  image: {
    // perspectiveOrigin: 'left center',
    // transformStyle: 'preserve-3d',
    // perspective: 1500,
    width: 388 / theme.screenSize + 'vw',
    height: 'auto'
  },
  subscribeBox: {
    marginTop: -50 / theme.screenSize + 'vw'
  },
  paddingTop: {
    paddingTop: 75 / theme.screenSize + 'vw'
  },full: {
    width: '100% !important', 
    maxWidth: '100% !important',
    height: '100%'
  }, button: {
    height: '28vh', 
    // background: 'yellow'
  }, 
  answer: {
    fontSize: 25,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  paperRoot: {
    width: 293 / theme.screenSize + 'vw',
    height: 48 / theme.screenSize + 'vw',
    border: '1px solid #80FFDB',
    background: theme.palette.text.hint,
    borderRadius: 99 / theme.screenSize + 'vw',
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
}));

const Question1 = ({ className, ...rest }) => {
  const classes = useStyles();
  const {title, question, answers, answer, setControl, control} = rest;

  // var answers = [1, 2, 3, 4]

  const handleChangeButton = (value) => {
    if (value == answer){
      setControl(control + 1)
    }else{
      window.location.reload()
    }
    // this.props.setFieldValue('degreeLevel', e.currentTarget.value);
} 

  return (
    <div {...rest}>
      <Container >
        {/* <Typography variant="h5" color="secondary" align="center">
          Explore
        </Typography> */}
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.root}
        >

          <Grid item xs={3} className={classes.full}>
                    <Box>
              <Typography
                className={classes.gradientTitle}
                align="center"
              >
                {title}
              </Typography>
            </Box>
            <Box p={2} />
            <Box>
              <Typography
                className={classes.title}
                align="center"
              >
                {question}
              </Typography>
            </Box>
            </Grid> 
            <Grid item xs={9}    className={classes.full}>

            <Grid
          container
          spacing={3}
          direction="column"
          alignItems="normal"
          justify="space-around"
          className={classes.full}

        >
          <Grid item >

          <Paper
      component="button"
      className={classes.root}
      onClick={() => {
        handleChangeButton(2)
      }}
    >

      <InputBase
        className={classes.input}
        placeholder="Match By Symbol"
        inputProps={{ 'aria-label': 'Match By Symbol' }}
      />

    </Paper>
        </Grid>
        </Grid>

        </Grid>

        </Grid>

      </Container>
    </div>
  );
};

export default Question1;
