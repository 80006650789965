import React from 'react';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  InputBase,
  IconButton,
  Typography
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

// import Typography from 'src/material/Typography';

const useStylesInputButton = makeStyles((theme) => ({
  root: {
    width: 100 + 'vw !important',
    height: 100 + 'vh !important',
    border: '1.5px solid #80FFDB',
    background: '#282c34'
    // borderRadius: 38 / theme.screenSize + 'vw',
    // display: 'flex',
    // alignItems: 'center',
    // boxShadow:
    //   '0 0 0rem #fff, 0 0 0rem #fff, 0 0 0.5rem #80FFDB, 0 0 0rem #80FFDB, 0 0 0rem #80FFDB, inset 0 0 0rem #80FFDB'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    width: 108 / theme.screenSize + 'vw',
    height: 38 / theme.screenSize + 'vw',
    borderRadius:
      ' 0 ' + 38 / theme.screenSize + 'vw ' + 38 / theme.screenSize + 'vw 0',
    background: '#80FFDB !important',
    color: '#242526'
    //   padding: 10,
  },
  divider: {
    height: 28,
    margin: 4
  },
  boxFilter: {
    display: 'flex',
    alignItems: 'center',
    width: 322 / theme.screenSize + 'vw',
    height: 38 / theme.screenSize + 'vw'
    // filter: 'drop-shadow(0px 0px 13px #80FFDB)'
  }
}));


const useStyles = makeStyles((theme) => ({
  root: {
    width: 90 + 'vw !important',
    height: 200 + 'vh !important',
    [theme.breakpoints.up('lg')]: {
      height: 90 + 'vh !important',
    },
    border: '1.5px solid #80FFDB',
    background: '#282c34',
    boxShadow:
      '0 0 0rem #fff, 0 0 0rem #fff, 0 0 0.5rem #80FFDB, 0 0 0rem #80FFDB, 0 0 0rem #80FFDB, inset 0 0 0rem #80FFDB'
},
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  gradientTitle: {
    width: '100%',
    fontSize: 30,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 800,
    // lineHeight: 28,
    background: '-webkit-linear-gradient(#9BD2C8, #4EC5AF)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  },
  title: {
    width: '100%',
    fontSize: 25,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 800,
    // lineHeight: 28,
    // background: '-webkit-linear-gradient(#9BD2C8, #4EC5AF)',
    // '-webkit-background-clip': 'text',
    // '-webkit-text-fill-color': 'transparent'
  },
  description: {
    paddingLeft: '5vw',
    paddingRight: '5vw',
  },
  image: {
    // perspectiveOrigin: 'left center',
    // transformStyle: 'preserve-3d',
    // perspective: 1500,
    width: 388 / theme.screenSize + 'vw',
    height: 'auto'
  },
  subscribeBox: {
    marginTop: -50 / theme.screenSize + 'vw'
  },
  paddingTop: {
    paddingTop: 75 / theme.screenSize + 'vw'
  },full: {
    width: '100% !important', 
    maxWidth: '100% !important',
    height: '100%'
  }, button: {
    height: '28vh', 
    // background: 'yellow'
  }, 
  answer: {
    fontSize: 25,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Question1 = ({ className, ...rest }) => {
  const classes = useStyles();
  const {title, question, answers, answer, setControl, control} = rest;

  // var answers = [1, 2, 3, 4]
const [myValue, setMyValue] = React.useState('Type Here');


  const handleChangeButton = (value) => {
    if (value == answer){
      setControl(control + 1)
    }else{
      window.location.reload()
    }
    // this.props.setFieldValue('degreeLevel', e.currentTarget.value);
} 

if (myValue == '06/17/2021'){
  return (

    <div {...rest}>
    <Container >
      {/* <Typography variant="h5" color="secondary" align="center">
        Explore
      </Typography> */}
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="center"
        justify="center"
        className={classes.root}
      >

        <Grid item  xs={12} className={classes.full}>
                  <Box>
            <Typography
              className={classes.gradientTitle}
              align="center"
            >
              🎉🎉🎉🎉🎉 HAPPY BIRTHDAY 🎉🎉🎉🎉🎉
            </Typography>
          </Box>
          


      <Box>
          <Typography
              className={classes.description}
              align="center"
            >
              🎉🎉🎉🎉🎉 HAPPY BIRTHDAY 🎉🎉🎉🎉🎉
            </Typography>
            <Typography
              className={classes.description}
              align="center"
            >
              不知道你花了多少時間才終於到這裡 首先當然要祝你生日快樂啦 20歲了Babeee💞 
              可惜我不能在身邊陪你過生日對不起了 
              我知道你最近很累也對未來有很多未知 好抱歉我最近不能陪在你身邊陪你度過 但我希望你有任何煩惱或不開心都能跟我分享
              再一起的這些日子真的很開心 也做了很多事去了很多地方 
              有時候生活有些小摩擦 但也正是如此我才能更認識你 更了解彼此
              也是因為你 我也才發現了我很多的缺點 當然我不可能馬上變得很完美
              但我會努力變成更好的人 我真的很希望有天能讓你很開心 開心到泛淚
              很期待以後會西雅圖一起生活 期待冬天一起窩在被窩裡 或 去海邊在沙灘上看夕陽
              未來雖然遙遠 人常常會迷茫 雖然我不太會說好話 但我希望你知道我永遠會在身後支持你
              LOVE U BABE 見面後再邀大家去我們的apartment 辦party 吧！
              你現在或許還是不太懂為何我要build一個website 
              如果你仔細看url 的話... Surprise是你的domain name!!! 因為疫情也沒辦法見面 所以最後打算做一個website 給你
              之後我們再一起 設計你網站要長如何吧! 以後你的作品集或生活點滴都可以放在上面了：）以後一定會有很多很棒的底片照 好期待 我們可以explore Seattle！！！ 好啦 love 原諒我吧... 
              等見面後再向你好好賠罪 真的很期待到時又能見面 熬過這兩個月就行了！！ I REALLY MISS U! 
              Btw. 做這網站上找到了好多照片 不經想起很多點點滴滴 比方說聖誕節很冷的時候幫你為圍巾 或是熬夜不睡去吃宵夜 
              又或是聽我的playlist看這魚缸的魚
              真的很開心我們有那麼多的回億 希望我們永遠都會記得這些快樂的時光 💩💩💩💩💩💩💩💩

                        

            </Typography>
          </Box>
          
          <Box p={2}>
            <img style={{width:'auto', height:'35vh'}}src='/image/main.jpg' alt='loading'/>
                     </Box>

      </Grid>
      </Grid>

    </Container>
  </div>
  )
}

  return (
    <div {...rest}>
      <Container >
        {/* <Typography variant="h5" color="secondary" align="center">
          Explore
        </Typography> */}
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.root}
        >

          <Grid item xs={3} className={classes.full}>
                    <Box>
              <Typography
                className={classes.gradientTitle}
                align="center"
              >
                'Congrats! Last Challenge!! It should be easy to:) Good Luck'
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.description}
                align="center"
              >
                'Enter The Important Date With The Format of 03/30/2020'
              </Typography>
            </Box>
        </Grid>
        <Grid item xs={3} className={classes.full}>
                    <Box>
                    {/* <TextField
value={myValue}
onChange={(e) => setMyValue(e.target.value)}       
 id="outlined-secondary"
        label="Outlined secondary"
        variant="outlined"
        color="white"
        
      /> */}

<InputBase
        className={classes.margin}
        value={myValue}
onChange={(e) => setMyValue(e.target.value)}  
        inputProps={{ 'aria-label': 'naked' , style: {fontSize: 40, color: 'white'}}}
  InputLabelProps={{style: {fontSize: 40}}} 
      />
            </Box>
            
        </Grid>
        </Grid>

      </Container>
    </div>
  );
};

export default Question1;
