import React from 'react';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  InputBase,
  IconButton,
  Typography
} from '@material-ui/core';

// import Typography from 'src/material/Typography';

const useStylesInputButton = makeStyles((theme) => ({
  root: {
    width: 100 + 'vw !important',
    height: 100 + 'vh !important',
    border: '1.5px solid #80FFDB',
    background: '#282c34', 
    flexWrap: 'nowrap'
    // borderRadius: 38 / theme.screenSize + 'vw',
    // display: 'flex',
    // alignItems: 'center',
    // boxShadow:
    //   '0 0 0rem #fff, 0 0 0rem #fff, 0 0 0.5rem #80FFDB, 0 0 0rem #80FFDB, 0 0 0rem #80FFDB, inset 0 0 0rem #80FFDB'
  },
  nowWrap: {
    flexWrap: 'nowrap'

  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    width: 108 / theme.screenSize + 'vw',
    height: 38 / theme.screenSize + 'vw',
    borderRadius:
      ' 0 ' + 38 / theme.screenSize + 'vw ' + 38 / theme.screenSize + 'vw 0',
    background: '#80FFDB !important',
    color: '#242526'
    //   padding: 10,
  },
  divider: {
    height: 28,
    margin: 4
  },
  boxFilter: {
    display: 'flex',
    alignItems: 'center',
    width: 322 / theme.screenSize + 'vw',
    height: 38 / theme.screenSize + 'vw'
    // filter: 'drop-shadow(0px 0px 13px #80FFDB)'
  }
}));


const useStyles = makeStyles((theme) => ({
  root: {
    width: 90 + 'vw !important',
    height: 90 + 'vh !important',
    border: '1.5px solid #80FFDB',
    background: '#282c34',
    boxShadow:
      '0 0 0rem #fff, 0 0 0rem #fff, 0 0 0.5rem #80FFDB, 0 0 0rem #80FFDB, 0 0 0rem #80FFDB, inset 0 0 0rem #80FFDB',
      flexWrap: 'nowrap'
},
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  gradientTitle: {
    width: '100%',
    fontSize: 30,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 800,
    // lineHeight: 28,
    background: '-webkit-linear-gradient(#9BD2C8, #4EC5AF)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  },
  title: {
    width: '100%',
    fontSize: 25,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 800,
    // lineHeight: 28,
    // background: '-webkit-linear-gradient(#9BD2C8, #4EC5AF)',
    // '-webkit-background-clip': 'text',
    // '-webkit-text-fill-color': 'transparent'
  },
  description: {
    width: 324 / theme.screenSize + 'vw'
  },
  image: {
    // perspectiveOrigin: 'left center',
    // transformStyle: 'preserve-3d',
    // perspective: 1500,
    width: 388 / theme.screenSize + 'vw',
    height: 'auto'
  },
  subscribeBox: {
    marginTop: -50 / theme.screenSize + 'vw'
  },
  paddingTop: {
    paddingTop: 75 / theme.screenSize + 'vw'
  },full: {
    width: '100% !important', 
    maxWidth: '100% !important',
    height: '100%'
  }, button: {
    height: '20vh', 
    [theme.breakpoints.up('lg')]: {
      height: '28vh', 
    },
    // background: 'yellow'
  }, 
  answer: {
    fontSize: 25,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 500,
  }
}));

const links = [
  '/image/1.jpg',
  '/image/2.jpg',
  '/image/3.jpg',
  '/image/4.jpg',
  '/image/5.jpg',
  '/image/6.jpg',
  '/image/7.jpg',
  '/image/8.jpg',
  '/image/9.jpg',
  '/image/10.jpg',
  '/image/11.jpg',
  '/image/12.jpg',
  '/image/13.jpg',
  '/image/14.jpg',
  '/image/15.jpg',
  '/image/16.jpg',
  '/image/17.jpg',

  '/image/18.jpg',
  '/image/19.jpg',
  '/image/20.jpg',
  '/image/21.jpg',
  '/image/22.jpg',
  '/image/23.jpg',
  '/image/24.jpg',
  '/image/25.jpg',


]

const texts = [
  'Hahah Handsome Eugene！',
  'lovely picture! Miss u:(',
  '我其實還蠻喜歡這張的ＸＤ',
  '第一次去台北 後來一起找我朋友',
  '第一次約會～',
  '神奇的一晚',
  '開心的艾比：）',
  'Golden Hour 濕美高地!!!',
  '何時幫我清痘痘',
  '這張真的很可愛<3',
  '九分真的很美',
  '大紅燈籠高高掛',
  'Golden hour 小琉球',
  '胖胖二人組',
  'One of my favoraite',
  '謝謝那晚你來找我 對不起我常常生悶氣',
  '台北夜深人靜之時',

  '你超像熊熊的',
  'Love You Babe U r beautiful!',
  'sry 🐘is not for u ',
  '去斑馬的路上～',
  '超可愛的',
  'Really enjoy that night:)',
  'Magic!!!',
  '改天要回去鹿野哦！',


]
const Question1 = ({ className, ...rest }) => {
  const classes = useStyles();
  const {title, question, answers, answer, setControl, control} = rest;

  const [wrong, setWrong] = React.useState(false)
  // var answers = [1, 2, 3, 4]

  const handleChangeButton = (value) => {

    if (value == answer || answer==4){
      setControl(control + 1)
    }else{
      setWrong(true);
      // window.location.reload()
    }
    // this.props.setFieldValue('degreeLevel', e.currentTarget.value);
} 
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
if (wrong){
  let index =getRandomInt(links.length)
  return (

    <div {...rest}>
    <Container >
      {/* <Typography variant="h5" color="secondary" align="center">
        Explore
      </Typography> */}
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.root}
      >

        <Grid item xs={2} className={classes.full}>
                  <Box>
            <Typography
              className={classes.gradientTitle}
              align="center"
            >
              {'Your are wrong! Babe! Refresh!!!'}
            </Typography>
            
          </Box>
          <Box p={2} />
            <Box>
              <Typography
                className={classes.title}
                align="center"
              >
                {texts[index]}
              </Typography>
            </Box>
          </Grid> 
          <Grid item xs={10}    className={classes.full}>
            <div style={{width:'auto', height: '60vh'}}>
              <img style={{width:'auto', height: '60vh'}} src={links[index]} alt='loading'/>          
            </div>
            </Grid>
          </Grid>
          </Container>
          </div>
  );
}


  return (
    <div {...rest}>
      <Container >
        {/* <Typography variant="h5" color="secondary" align="center">
          Explore
        </Typography> */}
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.root}
        >

          <Grid item xs={3} className={classes.full}>
                    <Box>
              <Typography
                className={classes.gradientTitle}
                align="center"
              >
                {title}
              </Typography>
            </Box>
            <Box p={2} />
            <Box>
              <Typography
                className={classes.title}
                align="center"
              >
                {question}
              </Typography>
            </Box>
            </Grid> 
            <Grid item xs={9}    className={classes.full}>

            <Grid
          container
          spacing={3}
          direction="column"
          alignItems="normal"
          justify="space-around"
          className={classes.full}

        >
          <Grid item >
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={6}>
            <Box>
              <Button fullWidth   className={classes.button} style={{background: '#6AEABC'}} onClick={()=> {handleChangeButton(0)}}> 
              <Typography
                className={classes.answer}
              >
{answers[0]}              </Typography>              

</Button>
              </Box>
          </Grid>

          <Grid item xs={6}  >
          <Box>
              <Button fullWidth className={classes.button} style={{background: '#EA3C5C'}} onClick={()=> {handleChangeButton(1)}}> 
              <Typography
                className={classes.answer}
              >
{answers[1]}              </Typography>                 </Button>
              </Box>
          </Grid>


          </Grid>
          </Grid>


 {/* --------------------------------- */}

 <Grid item >
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={6}>
            <Box>
              <Button fullWidth className={classes.button} style={{background: '#455ACC'}} onClick={()=> {handleChangeButton(2)}}> 
              <Typography
                className={classes.answer}
              >
{answers[2]}              </Typography>                 </Button>
              </Box>
          </Grid>

          <Grid item xs={6}>
          <Box>
              <Button fullWidth  className={classes.button} style={{background: '#FFD12D'}} onClick={()=> {handleChangeButton(3)}}> 
              <Typography
                className={classes.answer}
              >
{answers[3]}              </Typography>                 </Button>
              </Box>
          </Grid>


          </Grid>

           {/* --------------------------------- */}


          </Grid>

        </Grid>
        </Grid>

        </Grid>

      </Container>
    </div>
  );
};

export default Question1;
