import logo from './logo.svg';
import './App.css';
import Question1 from './Views/Questions/Question1'
import React from 'react';
import End from './Views/End'
import Text from './Views/Questions/Text'


const listOfQuestions = [
{
  title: 'Welcome! To The Web, A...', 
  question: 'Wait who are you?', 
  answers: ['Abby','Oscar','Rachel','Eugene'],
  answer: 0
},
{
  title: "You'r sure u r Abby? Then, you should know...", 
  question: 'when is the first date that we met?', 
  answers: ['12/1/2019','12/25/2019','12/12/2019','12/30/2019'],
  answer: 2
},
{
  title: "❤️ ❤️ ❤️ ❤️ ❤️ ❤️ ", 
  question: "Well! Here are some challenges for you before you finding out what is this website!", 
  answers: ['Quit','Challenge Accept','Good bye',':('],
  answer: 1
},
{
  title: 'Start from an easy question', 
  question: 'When is the first date of our relationship?', 
  answers: ['3/28/2020','3/29/2020','3/30/2019','3/30/2020'],
  answer: 3
},
{
  title: '轉眼間 我們也再一起400多天了 經歷過點點滴滴', 
  question: '你還記得第一次我陪你去台北的地方嗎', 
  answers: ['松山','二張','淡水','信義'],
  answer: 1
},
{
  title: '後來也常常去台北 北車也成為我們熟悉的地方', 
  question: '那給你猜猜我最喜歡的酒吧', 
  answers: ['Ｌa Boheme Cafe','老闆娘','販賣機','台虎'],
  answer: 0
},
{
  title: "真的很喜歡那家店吧！ 除了台北我們也一起去了不同地方旅行", 
  question: '找機會我們再回去...', 
  answers: ['內灣','小琉球','台東','台中'],
  answer: 2
},
{
  title: "找山豬他們 並去稻田裡拍照", 
  question: '那你知道我最喜歡哪間我們去的餐廳嗎？', 
  answers: ['內灣劇院','春日部',"Isabella's cafe",'大巴六九'],
  answer: 2
},
{
  title: "真的很喜歡那次約會 我們也去了很多演唱會", 
  question: '我們最喜歡的 band 之一', 
  answers: ['溫室雜草','椅子','五月天','王洛琳'],
  answer: 1
},
{
  title: "有機會可以去聽你的海龜 哈哈哈", 
  question: '話說你還記得你很喜歡我的playlist的名字嗎？', 
  answers: ['IDK','For Abby','The Soft', 'Empty'],
  answer: 3
},
{
  title: "我真的很喜歡聽著它並看魚缸放鬆 btw.我很喜歡你拍魚缸的照片", 
  question: '來個很隨機的題目ＸＤ 我在ikea抱著拍照的動物', 
  answers: ['狗','🐷','大象','大雄'],
  answer: 0
},
{
  title: "其實我真的很喜歡我們在ikea的約會：） 之後可以去西雅圖的～", 
  question: '如果有機會 我們以後一起！', 
  answers: ['南法','LA','中國','紐約'],
  answer: 0
},
{
  title: "結婚前 我們再去那種南法瑞士的山丘/農莊上生活", 
  question: '約好囉？', 
  answers: ['awwww','ughhh','💩','Nah'],
  answer: 2
},
{
  title: "或許很難想像 那天的到來 但至少我真得很開心遇見你", 
  question: '我也知道我有很多缺點 比如說', 
  answers: ['睡太多','自以為是','不會講話','太可愛'],
  answer:   3
},
{
  title: "但我真的希望你能等到我變好的那天", 
  question: '我知道我很不會說話 但我還是想說', 
  answers: ['I','Love','You','Babe'],
  answer:   4
},



]
function App() {
  const [control, setControl] =  React.useState(0);


  

  return (
    <div className="App">
      <header className="App-header">
        { control < listOfQuestions.length ? 
        <Question1 
          title={listOfQuestions[control].title}
          question={listOfQuestions[control].question}
          answers={listOfQuestions[control].answers}
          answer={listOfQuestions[control].answer}
          setControl={setControl}
          control={control}
        />: <End /> }
      </header>
    </div>
  );
}

export default App;
